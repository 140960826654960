<template>
  <div class="new-password-container">
    <v-card class="new-password-form">
      <DialogHeader icon="mdi-fingerprint" text="Set New Password"></DialogHeader>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-alert
            v-if="authError"
            dense
            text
            type="error"
          >
            {{authError}}
          </v-alert>

          <v-text-field
            label="Password"
            type="password"
            placeholder="Provide your password"
            v-model="password"
            :rules="formRules.passwordRules"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          type="submit"
          @click.prevent="onChangePasswordSubmit"
        >
          Change Password
        </v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import router from '@/router';
import { AuthTypes, AUTH_STORE } from '@/store/auth';
import { ref, reactive } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import DialogHeader from '@/components/DialogHeader.vue';

const { useState, useActions } = createNamespacedHelpers(AUTH_STORE);

export default {
  name: 'NewPassword',
  components: { DialogHeader },
  setup() {
    const form = ref(null);
    const valid = ref(false);
    const password = ref('');

    const formRules = reactive({
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
    });

    const { provideNewPassword } = useActions([AuthTypes.actions.provideNewPassword]);
    const { authError, user } = useState([AuthTypes.state.authError, AuthTypes.state.user]);

    async function onChangePasswordSubmit() {
      form.value.validate();
      if (valid.value) {
        const response = await provideNewPassword({ user: user.value, password: password.value });
        if (response === AuthTypes.notifications.PROVIDE_NEW_PASSWORD_SUCCESS) {
          router.push({ name: 'Clients' });
        }
      }
    }

    return {
      form,
      valid,
      formRules,
      authError,
      password,
      onChangePasswordSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
  .new-password-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .new-password-form {
      width: 600px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
</style>
